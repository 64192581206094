.pp-promo-block {
    width: 100%;
    min-height: 80px;
    background: @brand-color;
    box-shadow: inset 0 -5px 10px 0 #884601;
    margin: -5px auto 0;
    padding: 0;
    .pp-promo-block-wrip {
        width: 850px;
        margin: auto;
        min-height: 80px;
        text-align: left;
    }
    .pp-promo-block--img_arni {
        float: left;
        margin-top: 2px;
    }
    .pp-promo-block--distr {
        font-family: @font-family-brand;
        font-size: 30px;
        color: @text-color-white;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
        display: block;
        float: left;
        margin: 25px 40px;
    }
    .pp-promo-block--button {
        display: inline-block;
        float: left;
        padding: 11px 15px 11px;
        background: @text-color-white;
        border: 1px solid #fff;
        border-radius: 5px;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 20px;
        color: @brand-color;
        margin: 20px;
        height: 44px;
        cursor: pointer;
        &:hover {
            background: @brand-color;
            color: #fff;
            border-color: #fff;
            text-shadow: -1px -1px 1px #884601;
        }
        &.pp-arrow_box {
            position: relative;
        }
        &.pp-arrow_box:after {
            left: 100%;
            top: 50%;
            border: 1px solid #fff;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-left-color: #fff;
            border-width: 20px;
            margin-top: -20px;
        }
    }
}

#pp-popup {
    display: none;
    &.pp-popup {
        background-color: #fff;
        width: 320px;
        box-shadow: 0 0 20px #000;
        .b-close {
            background: #fff;
            color: @brand-color;
            font-size: 22px;
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            box-shadow: 0 0 20px #000;
        }
    }
    .pp-popup--header {}
    .pp-popup--title {
        font-family: @font-family-brand;
        font-size: 30px;
        text-transform: uppercase;
        color: @brand-color;
        margin: 15px 0 5px;
    }
    .pp-popup--distr {
        font-size: 20px;
        margin: 25px 0 25px;
        img {
            margin-bottom: -3px;
        }
    }
    .pp-popup--email-form {
        width: 90%;
        margin: auto;
    }
    .pp-popup--form-label {
        font-size: 14px;
        margin: 0 0 2px;
    }
    .pp-popup--text-input {
        width: 100%;
        margin: 5px 0;
        text-align: center;
        height: 39px;
        font-size: 18px;
        color: #999;
        border-radius: 5px;
        border: none;
        box-shadow: inset 0 0 5px @brand-color;
    }
    .pp-popup--button {
        width: 100%;
        margin: 5px 0;
        text-align: center;
        text-transform: uppercase;
        height: 39px;
        font-size: 18px;
        color: #fff;
        background: @brand-color;
        border-radius: 5px;
        border: 1px solid @brand-color;
    }
    .pp-popup--txt {
        margin: 5px 0 15px;
    }
    .pp-company-name {}
    .pp-popup-footer {}
    .pp-popup--footer-txt {
        color: @brand-color;
        font-size: 20px;
    }
    .pp-popup--footer-subtxt {
        font-size: 14px;
    }
}