body {
    min-width: 320px;
    margin: 0;
    font-family: @font-family;
    color: @text-color;
    text-align: center;
}

.pp-clearfix:after {
    content: ".";
    visibility: hidden;
    display: block !important;
    height: 0;
    clear: both;
}